.curtain {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(125, 255,255,1);
    display: flex;
    align-items: center;justify-content: center;
    transition: .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}