.filter {
   position: fixed;
   top: 0;
   left: 0;
   width: 25vw;
   height: 100vh;
   background-color: rgba(0, 255,255, 1);
   transition: .5s ease-in-out;
   transform: translateX(-100%);
   z-index: 4;
   padding: 10px;
   &.opened {
      transform: none;
   }
   &__call {
      position: absolute;
      left:100%;
      bottom:0;
   }

}