
.flyby-controls {
   position: absolute;
   left: 0;
   top: 50%;
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   transform: translateY(-50%);
   &.disabled {
      cursor: wait;
      pointer-events: none;
   }
}
.flyby-control {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-color: black;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   cursor: pointer;
   &__next {
      
   }
   &_prev {

   }
}