.delete-icon {
    cursor: pointer;
    &:hover &-inner {
        transform: rotate(180deg);
    }
}
.delete-icon-inner {
    transform-origin: center;
    transform-box: fill-box;
    transition: .4s ease-in-out;
}