.svg-canvas {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &.blocked { 
        pointer-events: none;
    }
    polygon {
        fill: rgba(0, 255, 255, 0.5);
        transition: .3s ease-in-out;
        cursor: pointer;
        animation: fadeIn .3s ease-in-out 1;
        &:hover {
            fill: rgba(0, 255, 255, 1);
        }
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        
        opacity: 1;
    }
}